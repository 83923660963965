// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/profile.jpg";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import sass from "./assets/techstack/sass.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import figma from "./assets/techstack/figma.png";
// Porject Images
import projectImage1 from "./assets/projects/project1.jpg";
import projectImage2 from "./assets/projects/project2.jpg";
import projectImage3 from "./assets/projects/project3.jpg";
import projectImage4 from "./assets/projects/project4.jpg";
import projectImage5 from "./assets/projects/project5.jpg";
import projectImage6 from "./assets/projects/project6.jpg";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Ismail dev",
  tagline: "I build things for web and android",
  img: profile,
  about: `Hey there! 🇲🇦I'm a Full Stack Web Developer. I can help you using the following technologies #html #css #javascript #nodejs #webAgency #web_agency`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/ismail-dev/",
  github: "https://github.com/ismail-developer",
  twitter: "https://x.com/IsmailCoder",
  instagram: "https://www.instagram.com/ismail_developer/",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Frontend Web Developer",
    Company: `startup`,
    Location: "Tangier",
    Type: "Full Time",
    Duration: "Sep 2021 - Dec 2024",
  },
  {
    Position: "back-end developer nodejs",
    Company: `Freelancer `,
    Location: "tangier-morocco",
    Type: "Internship",
    Duration: "Sep 2021 - Dec 2024",
  },
  {
    Position: "python bot",
    Company: `Freeelancer startup `,
    Location: "Tangier-morocco",
    Type: "Internship",
    Duration: "Sep 2021 - Dec 2024",
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Frontend Development",
    Company: "Udemy, YouTube, Google, Medium",
    Location: "Online",
    Type: "Full Time",
    Duration: "Jan 2022 - Present",
  },
  {
    Position: "Bachelor in Electronics & Communication",
    Company: `Your College Name here`,
    Location: "Bengaluru",
    Type: "Full Time",
    Duration: "Aug 2020 - Present",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title:"Landing page",
    image:"https://i.pinimg.com/originals/a7/bd/e7/a7bde7b2e42f5ee6f11873460ee866bc.jpg",
    description:"A sleek and modern landing page designed to capture user attention and drive engagement. This project showcases a clean and responsive layout with a focus on usability and aesthetics",
    techstack:"html,css,js,react,material-ui",
    previewLink:"/projects/landing-page",
    githubLink:""
  },
];

//enter project all
export const projects=[
  {
    "type": "landing-page",
    "title": "E-shop",
    "description": "A sleek and modern eCommerce app that offers a wide variety of fashion clothing, accessories, and shoes for all genders.",
    "previewLink": "landing-page/e-shop"
  },
  {
    "type": "landing-page",
    "title": "Gadget Hub",
    "description": "An eCommerce app for the latest tech gadgets and electronics, featuring exclusive discounts and customer reviews.",
    "previewLink": "https://gadgethub.com"
  },
  {
    "type": "landing-page",
    "title": "Organic Mart",
    "description": "A marketplace for organic food products, offering fresh produce and sustainable options for health-conscious shoppers.",
    "previewLink": "https://organicmart.com"
  },
  {
    "type": "landing-page",
    "title": "Home Decor",
    "description": "Discover beautiful furniture and home decor items to enhance your living space with our easy-to-use eCommerce app.",
    "previewLink": "https://homedecor.com"
  },
  {
    "type": "landing-page",
    "title": "Beauty Bazaar",
    "description": "An eCommerce app dedicated to beauty and skincare products, offering premium brands and expert tips.",
    "previewLink": "https://beautybazaar.com"
  },
  {
    "type": "landing-page",
    "title": "Book Haven",
    "description": "A comprehensive app for book lovers, providing a wide collection of novels, educational books, and audiobooks.",
    "previewLink": "https://bookhaven.com"
  },
  {
    "type": "landing-page",
    "title": "Pet Supplies",
    "description": "A dedicated app for pet owners, offering everything from pet food to toys, grooming supplies, and accessories.",
    "previewLink": "https://petsupplies.com"
  },
  {
    "type": "landing-page",
    "title": "Sporty Shop",
    "description": "An eCommerce app for sports enthusiasts, offering sports equipment, apparel, and accessories for various activities.",
    "previewLink": "https://sportyshop.com"
  },
  {
    "type": "landing-page",
    "title": "Baby Essentials",
    "description": "Shop for baby care products, clothing, and essentials with our user-friendly eCommerce app.",
    "previewLink": "https://babyessentials.com"
  },
  {
    "type": "landing-page",
    "title": "Luxury Timepieces",
    "description": "A high-end eCommerce app featuring luxury watches, with detailed product descriptions and secure payment options.",
    "previewLink": "https://luxurytimepieces.com"
  }
].slice(0,1)

// Enter your Contact Details here
export const contactDetails = {
  email: "ismail_coder@protonmail.com",
  phone: "+212 66666666",
};
